<template>
  <div class="password">
    <Card :parent_id="parent_id">
      <p class="ti">修改密码</p>
    </Card>
    <div class="risk">
      <p>
        风险提示：你的账号密码过于简单（8位及以上数字/字母/字符/字符相结合的密码才足够安全哦！）
      </p>
    </div>
    <div class="step" style="">
      <div class="step-box">
        <!-- <el-steps :active="active" finish-status="success">
          <el-step title="安全认证"></el-step>
          <el-step title="输入新密码"></el-step>
          <el-step title="修改成功"></el-step>
        </el-steps>
        <el-button style="margin-top: 12px" @click="next">下一步</el-button>
         -->
        <!-- 步骤条 -->
        <div class="steps">
          <div class="is-horizontal">
            <div class="horizontal">
              <div ref="first" class="horizontal-box active">
                <div>1</div>
              </div>
              <ul ref="SmallCircle">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div ref="firstTitle" class="title active">安全认证</div>
          </div>
          <div class="is-horizontal">
            <div class="horizontal">
              <div ref="second" class="horizontal-box">
                <div>2</div>
              </div>
              <ul ref="SmallCircle1">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div ref="secondTitle" class="title">输入新密码</div>
          </div>
          <div class="is-horizontal">
            <div class="horizontal">
              <div ref="third" class="horizontal-box">
                <div>3</div>
              </div>
            </div>
            <div ref="thirdTitle" class="title">修改成功</div>
          </div>
        </div>
        <!-- 第一步 -->
        <div class="step-content" v-show="first">
          <div class="step-phone">
            <p>
              手机号
              <span style="color: #fd686e">{{
                phonenumber | mobileFilter
              }}</span>
            </p>
          </div>
          <div class="step-yzm">
            <p>验证码</p>
            <el-input v-model="yzm" placeholder="请输入验证码"></el-input>
            <!-- <div class="yzm-btn" @click="Yzm" ref="yzm">
              <div v-show="showYzm">获取验证码</div>
              <div v-show="!showYzm">已发送（{{ a }}s)</div>
            </div> -->
            <el-button
              ref="btn"
              :disabled="disabled"
              :plain="true"
              @click="sendcode"
            >
              {{ btntxt }}
            </el-button>
          </div>

          <div class="btn"><div class="Btn" @click="BtNum(1)">下一步</div></div>
          <p class="step-ti">如你已更换手机号，请致电4008-234-521</p>
        </div>
        <!-- 第二步 -->
        <div class="step-password" v-show="second">
          <div style="margin-top: 40px">请输入8-20位数字、字母、下划线</div>
          <div class="password">
            <div class="pw">
              <p>当前密码</p>
              <!-- @blur="getpassword" -->
              <el-input v-model="oldPw" placeholder="请输入当前密码"></el-input>
              <p class="mmk" v-show="ff">当前密码不能空</p>
            </div>

            <div class="pw">
              <p>新密码</p>
              <!-- @blur="sqjd" -->
              <el-input
                v-model="newPw"
                type="password"
                placeholder="请输入新密码"
                maxlength="20"
              ></el-input>
              <p v-show="aa" class="mmk">新密码不能为空</p>
              <p v-show="bb" class="mmk">新密码不能少于8位</p>
              <p v-show="cc" class="mmk">新密码不能由纯数字或者纯字母组成</p>
              <p v-show="hh" class="mmk">新密码不能与当前密码一样</p>
            </div>

            <div class="pw">
              <p>确认密码</p>
              <!-- @blur="qrmm" -->
              <el-input
                type="password"
                v-model="nowPw"
                placeholder="请再次输入新密码"
                maxlength="20"
              ></el-input>
              <p v-show="dd" class="mmk">确认密码不能为空</p>
              <p v-show="ee" class="mmk">确认密码与新密码不同,请检查后重试</p>
            </div>
            <p class="gg" v-show="gg">当前密码输入错误</p>
            <div class="btn" @click="BtNum(2)">下一步</div>
          </div>
        </div>

        <!-- 重新登录 -->
        <div class="LogAgain" v-show="third">
          <p>您的登录密码已修改，请重新登录</p>
          <div class="Again" @click="login">马上登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { infocur } from "@/api/login/login.js";
import local from "@/api/common/local.js";
import Card from "@/components/Card";
// import Steps from "@/components/Steps";
import { passwprd } from "@/api/my/my.js";
import { subTk, sendSms, validSms } from "@/api/member/member.js";
import { infocur } from "@/api/login/login.js";
export default {
  filters: {
    mobileFilter(val) {
      let reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, "$1****$2");
    },
  },
  data() {
    return {
      active: 1,
      yzm: "",
      showYzm: true,
      a: 10,
      first: true,
      second: false,
      third: false,
      nowPw: "",
      oldPw: "",
      newPw: "",
      phonenumber: "",
      ff: false,
      aa: false,
      bb: false,
      cc: false,
      dd: false,
      ee: false,
      gg: false,
      hh: false,
      disabled: false,
      num: 30,
      btntxt: "获取验证码",
      parent_id: "",
    };
  },
  components: {
    Card,
  },
  created() {
    // this.getpassword();
    this.BtNum();
    this.tkL();
    this.chuhua();
  },
  methods: {
    Btn() {
      if (this.num > 0) {
        this.num--;
        this.btntxt = `已发送(${this.num}s)`;
        setTimeout(this.Btn, 1000);
        this.$refs.btn.$el.style.backgroundColor = "#fafafaff";
        this.$refs.btn.$el.style.color = "#ADADADFF";
      } else {
        this.btntxt = "重新发送";
        this.$refs.btn.$el.style.backgroundColor = "#FD686EFF";
        this.$refs.btn.$el.style.color = "#ffffff";
        this.disabled = false;
      }
    },
    //验证码
    async sendcode() {
      this.num = 30;
      this.disabled = true;
      this.Btn();
      let a = {
        busType: 2,
        // phonenumber: this.phonenumber,
      };
      const { code, data } = await sendSms(a);
      if (code == 0) {
        console.log("数据", data);
      }
    },
    //临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async chuhua() {
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        this.phonenumber = data.user.phonenumber;
        this.parent_id = data.user.id;
      }
    },
    async BtNum(num) {
      if (num == 1) {
        let a = {
          code: this.yzm,
        };
        const { code, data } = await validSms(a);
        if (code == 0) {
          console.log("00", data);
          this.first = false;
          this.second = true;
          this.$refs.SmallCircle.getElementsByTagName("li").forEach((item) => {
            item.style.backgroundColor = "#fd686e";
          });
          this.$refs.second.style.backgroundColor = "#fd686e";
          this.$refs.first.style.backgroundColor = "#ADADADFF";
          this.$refs.firstTitle.style.color = "#adadadff";
          this.$refs.secondTitle.style.color = "#000";
        } else {
          this.$message.error({
            message: "验证码错误!",
            offset: 100,
          });
        }
      } else if (num == 2) {
        let token = local.get("access_token");
        let Data = {
          token: token,
          oldPwd: this.oldPw,
          newPwd: this.newPw,
          againPwd: this.nowPw,
          source: 1,
        };
        this.tkL();
        if (this.oldPw == "" && this.newPw == "" && this.nowPw == "") {
          this.aa = true;
          this.dd = true;
          this.ff = true;
        } else {
          const a = await passwprd(Data);
          if (a.code == 0) {
            console.log(this.oldPwd, "当前密码");
            console.log("旧数据-----------------------");
            if (this.oldPw !== "" && this.newPw !== "" && this.nowPw !== "") {
              //跳转下一页
              this.second = false;
              this.third = true;
              this.$refs.SmallCircle.getElementsByTagName("li").forEach(
                (item) => {
                  item.style.backgroundColor = "#adadadff";
                }
              );
              this.$refs.SmallCircle1.getElementsByTagName("li").forEach(
                (item) => {
                  item.style.backgroundColor = "#fd686e";
                }
              );
              this.$refs.second.style.backgroundColor = "#ADADADFF";
              this.$refs.secondTitle.style.color = "#adadadff";
              this.$refs.thirdTitle.style.color = "#000";
              this.$refs.third.style.backgroundColor = "#fd686e";
              // 跳转登录页
              this.login();
              localStorage.removeItem("access_token");
            }
          } else if (a.code == 1) {
            this.second = true;
            this.third = false;
            this.gg = true;
            if (this.nowPw == "" && this.newPw == "") {
              this.gg = false;
              this.ff = true;
              this.aa = true;
              this.dd = true;
            }
          }
        }
      }
    },
    login() {
      let rel = this.$router.resolve({
        path: "/login",
      });
      window.open(rel.href, "_self");
    },
    Yzm() {
      this.showYzm = false;
      let time = setInterval(() => {
        this.a = this.a - 1;
        if (this.a == 0) {
          clearInterval(time);
        }
      }, 1000);
      this.$refs.yzm.style.backgroundColor = "#e3e3e3ff";
    },
  },
  watch: {
    newPw() {
      let reg =
      /^(?![0-9\W_!@#$%^&*`~()-+=]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)[0-9_A-Za-z\W_!@#$%^&*`~()-+=]{8,20}$/;
        // /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{8,20}$/;
      if (this.newPw == "") {
        this.aa = true;
        this.bb = false;
        this.cc = false;
      } else if (this.newPw.length < 8) {
        this.bb = true;
        this.aa = false;
        this.cc = false;
      } else if (!reg.test(this.newPw)) {
        this.cc = true;
        this.aa = false;
        this.bb = false;
      } else if (this.newPw == this.oldPw) {
        this.hh = true;
        this.cc = false;
        this.aa = false;
        this.bb = false;
      } else {
        this.hh = false;
        this.cc = false;
        this.aa = false;
        this.bb = false;
      }
    },
    nowPw() {
      if (this.nowPw == "") {
        this.dd = true;
        this.ee = false;
      } else if (this.nowPw !== this.newPw) {
        this.ee = true;
        this.dd = false;
        this.ff = false;
      } else {
        this.ee = false;
        this.dd = false;
        this.ff = false;
      }
    },
    oldPw() {
      if (this.oldPw == "") {
        this.ff = true;
        this.gg = false;
      } else {
        this.ff = false;
        this.gg = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.password {
  // width: 740px;
  position: relative;
  // font-size: 16px;
  .risk {
    position: absolute;
    left: -20px;
    width: 880px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fddfb5ff;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c78337;
      line-height: 20px;
    }
  }
  .step {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .step-box {
      width: 620px;
      display: flex;
      flex-direction: column;
      align-items: center;
      //   /deep/ .el-steps--horizontal {
      //     width: 620px;
      //   }
      .steps {
        display: flex;
        //   justify-content: space-between;
        justify-content: space-around;

        .is-horizontal {
          flex: 1;
          display: flex;
          flex-direction: column;

          .horizontal {
            display: flex;
            align-items: center;
            margin-left: 10px;
            .horizontal-box.active {
              width: 26px;
              height: 26px;
              background: #fd686e;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
            }
            .horizontal-box {
              width: 26px;
              height: 26px;
              background: #adadadff;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
            }
            ul {
              display: flex;
              align-items: center;
              li {
                width: 8px;
                height: 8px;
                margin: 0px 34px;
                background: #adadad;
                border-radius: 50%;
              }
            }
          }
          .title {
            margin-top: 16px;
          }
          .title.active {
            color: #000;
          }
          .title {
            color: #666;
          }
        }
      }
      //手机验证码
      .step-content {
        display: flex;
        flex-direction: column;
        margin-top: 36px;
        font-size: 16px;
        // align-items: center;
        // color: #8b76f9;
        // line-height: 35px;
        // height: 35px;
        width: 420px;
        .step-phone {
          // width: 348px;

          p {
            span {
              margin-left: 25px;
            }
          }
        }
        .step-yzm {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-top: 20px;
          // width: 348px;
          /deep/ .el-input {
            width: 180px;
            height: 40px;
          }
          /deep/ .el-input__inner {
            background: #f5f5f5ff;
            background-color: #f5f5f5ff;
            border: none;
            outline: none;
          }
          /deep/ .el-button {
            margin-left: 10px;
            background: #fd686e;
            color: #ffffff;
          }
          /deep/ .el-button.is-plain:hover {
            background: #fd686e;
            border: none;
          }
          p {
            margin-right: 32px;
          }
          .yzm-btn {
            width: 119px;
            height: 40px;
            margin-left: 25px;
            background: #fd686e;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          .Btn {
            margin-top: 110px;
            cursor: pointer;
            width: 130px;
            height: 40px;
            background: #fd686e;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
          }
        }
        .step-ti {
          font-size: 14px;
          color: #333;
          margin-top: 16px;
          text-align: center;
          vertical-align: middle;
        }
      }
      //密码重置
      .step-password {
        display: flex;
        flex-direction: column;
        align-items: center;
        .password {
          max-width: 540px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          .gg {
            color: #fd686e;
            position: absolute;
            top: 205px;
          }
          .pw {
            display: flex;
            align-items: center;
            margin-top: 20px;
            position: relative;
            .mmk {
              position: absolute;
              left: 320px;
              color: #fd686e;
              width: 240px;
              text-align: left;
            }
            /deep/ .el-input {
              width: 220px;
              height: 40px;
            }
            /deep/ .el-input__inner {
              //   background-color: #F5F5F5FF;
              background: #f5f5f5ff;
              border: none;
              outline: none;
            }
            p {
              width: 70px;
              text-align: right;
              margin-right: 24px;
            }
          }
        }
        .btn {
          margin-top: 50px;
          cursor: pointer;
          width: 130px;
          height: 40px;
          background: #fd686e;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
      }
      //重新登陆
      .LogAgain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        p {
          margin: 100px 0px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        .Again {
          width: 130px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fd686eff;
          color: #ffffff;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
